import ApiService from "./api.service";
import moment from "moment-timezone";
import { UPDATE_IS_BUSY } from "../store/claim.module";

const ClaimService = {
  store: null,
  init(opts) {
    if (opts.store) {
      this.store = opts.store;
    }
  },

  submitClaim() {
    let postForm = {};
    let store = this.store;
    store.dispatch(UPDATE_IS_BUSY, true);
    postForm = this.getBasePostForm();

    // award vehicle
    postForm.preferredAwardVehicle = store.getters.selectedAwardVehicle;
    postForm.browserSource = window.location.href;
    postForm.browserReferer = document.referrer || this.getReferrerFromQueryString();

    if (store.getters.mode == "ZERO_SALES") {
      postForm.zeroSales = true;
      postForm.zeroSalesDate = moment
        .tz(store.getters.selectedZeroSalesPeriod, store.getters.selectedClient.timezone)
        .format("YYYY-MM-DDTHH:mm:ss.SSSZ");
      postForm.uploads = [];
      postForm.claimProducts = [];
      postForm.claimFieldValues = [];
    } else {
      postForm.zeroSales = false;
      postForm.uploads =
        store.getters.documentation.existing && store.getters.documentation.existing.length > 0
          ? store.getters.documentation.existing
          : [];
      if (
        store.getters.claimUploads &&
        store.getters.claimUploads.existing &&
        store.getters.claimUploads.existing.length
      ) {
        postForm.uploads = postForm.uploads.concat(store.getters.claimUploads.existing);
      }

      postForm.claimProducts = this.getClaimProducts(
        store.getters.promotion,
        store.getters.claimProducts,
        store.getters.tradeInProducts
      );
      postForm.claimFieldValues = this.getClaimFieldValues(store.getters.claimFields, store.getters.claimFieldValues);

      postForm.productRegistrationItems = this.getProductsToRegister(
        store.getters.promotion,
        store.getters.productsToRegister
      );
    }

    return this.submit(postForm, store.getters.claimId).finally(() => {
      store.dispatch(UPDATE_IS_BUSY, false);
    });
  },
  submit(postForm, claimId) {
    let store = this.store;
    if (store.getters.claimId == undefined) {
      return ApiService.post("/api/claims/submit", postForm)
        .then(({ data }) => {
          return data;
        })
        .catch(error => {
          throw error;
        });
    } else {
      postForm.submitAfterUpdate = true;
      return ApiService.post("/api/claims/" + claimId + "/update", postForm)
        .then(({ data }) => {
          return data;
        })
        .catch(error => {
          throw error;
        });
    }
  },
  submitPublicClaim() {
    this.isBusy = true;

    let store = this.store;
    this.errors = {};

    let postForm = {};
    postForm.promotionKey = store.getters.promotion.promotionKey;
    // postForm.claimStage - allow this to be determined
    postForm.browserSource = window.location.href;
    postForm.browserReferer = document.referrer || this.getReferrerFromQueryString();
    postForm.participantTypeKey = "950"; // TODO - this shouldn't be hard-coded in a generic submit claim method.  It only applies to Sony.
    postForm.plaidAccountId = store.getters.plaidAccountId;
    postForm.participantStatus = { name: "ENR" };
    postForm.zeroSales = false;
    postForm.uploads =
      store.getters.documentation.existing && store.getters.documentation.existing.length > 0
        ? store.getters.documentation.existing
        : [];
    if (
      store.getters.claimUploads &&
      store.getters.claimUploads.existing &&
      store.getters.claimUploads.existing.length
    ) {
      postForm.uploads = postForm.uploads.concat(store.getters.claimUploads.existing);
    }

    postForm.claimProducts = this.getClaimProducts(
      store.getters.promotion,
      store.getters.claimProducts,
      store.getters.tradeInProducts
    );
    postForm.claimFieldValues = this.getClaimFieldValues(store.getters.claimFields, store.getters.claimFieldValues);
    postForm.reuseParticipant = true;
    postForm.optIn = store.getters.optIn;
    postForm.emailTemplateParticipantForm = {
      emailTemplateKey: "END_USER_CLAIM_SUBMISSION"
    };
    if (store.getters.selectedAwardVehicle) {
      postForm.awardVehicleKey = store.getters.selectedAwardVehicle.awardVehicleKey;
    }

    postForm.productRegistrationItems = this.getProductsToRegister(
      store.getters.promotion,
      store.getters.productsToRegister
    );

    return ApiService.postWithCaptcha("/api/claims/submit/public", postForm, null, "publicClaim")
      .then(({ data }) => {
        this.claimNumber = data.claimKey;
        this.isBusy = false;
        this.claimSubmitted = true;
        return Promise.resolve(data);
      })
      .catch(error => {
        console.log(error);
        this.errors = ApiService.getErrorsFromResponse(error);
        //this.$vuetify.goTo(0); doesn't work
        this.isBusy = false;
        return Promise.reject(this.errors);
      });
  },

  getReferrerFromQueryString() {
    var queryString = window.location.search.substring(1);
    var params = new URLSearchParams(queryString);

    if (params.has("referrer")) {
      return params.get("referrer");
    } else {
      return null;
    }
  },

  calculateTotalClaimAmount(availableAwardVehicles) {
    let postForm = {};
    let store = this.store;

    postForm = this.getBasePostForm();

    postForm.emailTemplateParticipantForm = null;

    // award vehicle
    postForm.preferredAwardVehicle = store.getters.selectedAwardVehicle;

    // Just in case we run into a situation where we are unable to determine an award vehicle, select an available one,
    if (!postForm.perferredAwardVehicle && availableAwardVehicles && availableAwardVehicles.length > 0) {
      postForm.preferredAwardVehicle = availableAwardVehicles[0];
    }

    if (store.getters.mode == "ZERO_SALES") {
      postForm.zeroSales = true;
      postForm.zeroSalesDate = moment
        .tz(store.getters.selectedZeroSalesPeriod, store.getters.selectedClient.timezone)
        .format("YYYY-MM-DDTHH:mm:ss.SSSZ");
      postForm.uploads = [];
      postForm.claimProducts = [];
      postForm.claimFieldValues = [];
    } else {
      postForm.zeroSales = false;
      postForm.uploads =
        store.getters.documentation.existing && store.getters.documentation.existing.length > 0
          ? store.getters.documentation.existing
          : [];
      if (
        store.getters.claimUploads &&
        store.getters.claimUploads.existing &&
        store.getters.claimUploads.existing.length
      ) {
        postForm.uploads = postForm.uploads.concat(store.getters.claimUploads.existing);
      }

      postForm.claimProducts = this.getClaimProducts(
        store.getters.promotion,
        store.getters.claimProducts,
        store.getters.tradeInProducts
      );
      postForm.claimFieldValues = this.getClaimFieldValues(store.getters.claimFields, store.getters.claimFieldValues);

      console.log(store.getters.productsToRegister);
      postForm.productRegistrationItems = this.getProductsToRegister(
        store.getters.promotion,
        store.getters.productsToRegister
      );
    }
    return ApiService.post("/api/claims/calculateTotalClaimAmount", postForm).then(({ data }) => {
      return data.totalClaimAmount;
    });
  },

  calculateTotalPublicClaimAmount(availableAwardVehicles, publicParticipantTypeKey) {
    let postForm = {};
    let store = this.store;

    postForm = this.getPublicBasePostForm();
    postForm.emailTemplateParticipantForm = null;
    postForm.reuseParticipant = true;

    postForm.emailTemplateParticipantForm = null;

    // award vehicle
    postForm.preferredAwardVehicle = store.getters.selectedAwardVehicle;

    // Just in case we run into a situation where we are unable to determine an award vehicle, select an available one,
    if (!postForm.perferredAwardVehicle && availableAwardVehicles && availableAwardVehicles.length > 0) {
      postForm.preferredAwardVehicle = availableAwardVehicles[0];
    }

    if (store.getters.mode == "ZERO_SALES") {
      postForm.zeroSales = true;
      postForm.zeroSalesDate = moment
        .tz(store.getters.selectedZeroSalesPeriod, store.getters.selectedClient.timezone)
        .format("YYYY-MM-DDTHH:mm:ss.SSSZ");
      postForm.uploads = [];
      postForm.claimProducts = [];
      postForm.claimFieldValues = [];
    } else {
      postForm.zeroSales = false;
      postForm.uploads =
        store.getters.documentation.existing && store.getters.documentation.existing.length > 0
          ? store.getters.documentation.existing
          : [];
      if (
        store.getters.claimUploads &&
        store.getters.claimUploads.existing &&
        store.getters.claimUploads.existing.length
      ) {
        postForm.uploads = postForm.uploads.concat(store.getters.claimUploads.existing);
      }

      postForm.claimProducts = this.getClaimProducts(
        store.getters.promotion,
        store.getters.claimProducts,
        store.getters.tradeInProducts
      );
      postForm.claimFieldValues = this.getClaimFieldValues(store.getters.claimFields, store.getters.claimFieldValues);

      console.log(store.getters.productsToRegister);

      postForm.productRegistrationItems = this.getProductsToRegister(
        store.getters.promotion,
        store.getters.productsToRegister
      );

      postForm.participantTypeKey = publicParticipantTypeKey;
    }
    return ApiService.postWithCaptcha(
      "/api/claims/calculateTotalClaimAmount/public",
      postForm,
      null,
      "calculateTotalClaimAmountPublic"
    ).then(({ data }) => {
      return data.totalClaimAmount;
    });
  },

  getBasePostForm() {
    let store = this.store;
    let basePostForm = {};
    basePostForm.promotionKey = store.getters.promotion.promotionKey;
    basePostForm.emailTemplateParticipantForm = {
      emailTemplateKey: "CLAIM_SUBMISSION"
    };
    basePostForm.saveEndUserForLater = store.getters.selectedEndUser ? false : store.getters.saveEndUserForLater;
    if (store.getters.participantSelectedToClaimOnBehalf && store.getters.participantSelectedToClaimOnBehalf.id) {
      basePostForm.participant = { id: store.getters.participantSelectedToClaimOnBehalf.id };
    } else {
      basePostForm.participant = { id: store.getters.selectedParticipant.id };
    }
    return basePostForm;
  },

  getPublicBasePostForm() {
    let store = this.store;
    let basePostForm = {};
    basePostForm.promotionKey = store.getters.promotion.promotionKey;
    basePostForm.emailTemplateParticipantForm = {
      emailTemplateKey: "CLAIM_SUBMISSION"
    };
    basePostForm.saveEndUserForLater = store.getters.selectedEndUser ? false : store.getters.saveEndUserForLater;
    if (store.getters.participantSelectedToClaimOnBehalf && store.getters.participantSelectedToClaimOnBehalf.id) {
      basePostForm.participant = { id: store.getters.participantSelectedToClaimOnBehalf.id };
    }
    return basePostForm;
  },

  getClaimFieldValues(claimFields, claimFieldValues) {
    const claimFieldsClone = claimFields.slice();
    let cfValues = [];
    claimFieldsClone.map(claimField => {
      if (
        claimFieldValues[claimField.id] &&
        claimFieldValues[claimField.id].existing &&
        claimFieldValues[claimField.id].existing.length > 0
      ) {
        claimFieldValues[claimField.id] = claimFieldValues[claimField.id].existing[0];
      }
      let cfValue = {
        claimField: { id: claimField.id, claimType: claimField.claimType },
        value:
          claimFieldValues[claimField.id] && claimFieldValues[claimField.id].name
            ? claimFieldValues[claimField.id].name.trim()
            : claimFieldValues[claimField.id] && claimFieldValues[claimField.id].id
            ? claimFieldValues[claimField.id].id
            : claimFieldValues[claimField.id]
            ? claimFieldValues[claimField.id].trim()
            : claimFieldValues[claimField.id]
      };
      cfValues.push(cfValue);
    });

    return cfValues;
  },

  getClaimProducts(promotion, claimProducts, tradeInProducts) {
    let claimProductsToReturn = [];
    [...(claimProducts ?? []), ...(tradeInProducts ?? [])].forEach(claimProduct => {
      let cp = Object.assign({}, claimProduct);
      cp.priceAmountCurrency = { name: promotion.country.currencyName };
      cp.promotionProduct = { id: claimProduct.promotionProduct.id };
      claimProductsToReturn.push(cp);
    });

    return claimProductsToReturn;
  },

  getProductsToRegister(promotion, productsToRegister) {
    let productsToRegisterToReturn = [];
    productsToRegister.forEach(productToRegister => {
      let productToRegisterCopy = Object.assign({}, productToRegister);
      productToRegisterCopy.priceAmountCurrency = { name: promotion.country.currencyName };
      productToRegisterCopy.product = { id: productToRegister.promotionProduct.product.id };
      productsToRegisterToReturn.push(productToRegisterCopy);
    });

    return productsToRegisterToReturn;
  },

  verifyPromotionInvitationKey(key, isPublic) {
    if (key && key.length > 0) {
      this.isBusy = true;
      if (isPublic) {
        return ApiService.get("/api/promotionInvitations/verify/" + key + "/public");
      } else {
        return ApiService.get("/api/promotionInvitations/verify/" + key);
      }
    } else {
      return Promise.reject("Empty key");
    }
  }
};

export default ClaimService;
