import ApiService from "./api.service";
import AuthService from "./auth.service";

const AwardVehicleService = {
  store: null,
  hqEmail: null,
  hqPhone: null,

  init(opts) {
    if (opts.store) {
      this.store = opts.store;
    }
  },

  /**
   * Depending on what is passed into this method, we'll attempt to find available award vehicles
   *
   * @param {*} participant Participant
   * @param {*} promotion Promotion
   * @returns available award vehicles
   */
  async getAvailableAwardVehicles(participant = null, promotion = null, isPublic = false) {
    if (promotion && isPublic) {
      return ApiService.get("/api/awardVehicles/available/byPromotion/" + promotion.id + "/public").then(({ data }) => {
        return data;
      });
    } else if (participant && promotion) {
      if (participant.id == this.store.getters.selectedParticipant.id) {
        return ApiService.get("/api/awardVehicles/available/byPromotion/" + promotion.id).then(({ data }) => {
          return data;
        });
      } else {
        return ApiService.get(
          "/api/awardVehicles/available/byPromotion/" + promotion.id + "/byParticipant/" + participant.id
        ).then(({ data }) => {
          return data;
        });
      }
    } else if (participant) {
      if (participant.id == this.store.getters.selectedParticipant.id) {
        return ApiService.get("/api/awardVehicles/available/byParticipant").then(({ data }) => {
          return data;
        });
      } else {
        return ApiService.get("/api/awardVehicles/available/byParticipant/" + participant.id).then(({ data }) => {
          return data;
        });
      }
    } else if (promotion) {
      return ApiService.get("/api/awardVehicles/available/byPromotion/" + promotion.id + "/public").then(({ data }) => {
        return data;
      });
    } else {
      return Promise.reject("No participant or promotion provided");
    }
  },

  /**
   * Get preferred award vehicle, unless it is forced
   *
   * @param {} participant
   * @returns preferred award vehicle
   */
  async getPreferredAwardVehicle(participant) {
    if (participant.forcedAwardVehicle) {
      return participant.forcedAwardVehicle;
    } else if (participant.organization && participant.organization.forcedAwardVehicle) {
      return participant.organization.forcedAwardVehicle;
    } else {
      return ApiService.get("/api/participants/" + participant.id + "/preferredAwardVehicle")
        .then(({ data }) => {
          return data;
        })
        .catch(() => {
          console.log("Preferred award vehicle not found");
        });
    }
  },

  async setSelectedAwardVehicleForCurrentParticipant(awardVehicle) {
    ApiService.patch("/api/participants/" + this.store.getters.selectedParticipant.id, {
      preferredAwardVehicle: ApiService.getSelfUrl("awardVehicles", awardVehicle)
    }).then(() => {
      this.store.getters.selectedParticipant.preferredAwardVehicle = awardVehicle;
      AuthService.storeSelectParticipant(this.store.getters.selectedParticipant);
    });
  }
};

export default AwardVehicleService;
