import Vue from "vue";
import App from "./App.vue";

// Routers
import routerCcpr from "./routerCcpr";
import routerCeur from "./routerCeur";
import routerCeurLanding from "./routerCeurLanding";
import routerCpr from "./routerCpr";
import routerEur from "./routerEur";
import routerEve from "./routerEve";

import SonyProgramService from "./plugins/services/sonyProgram.service";

import VueGtag from "vue-gtag";
import "./plugins/vue-currency-input";
import "./plugins/vue-meta";
import vuetify from "./plugins/vuetify";

import moment from "moment-timezone";

import GappComponents from "./gapp-components/main";

Vue.config.productionTip = false;

const host = window.location.host;
let comingSoon = false;

const chooseRouter = () => {
  let routes;

  switch (host) {
    case process.env.VUE_APP_PROGRAM_CPR_US_DOMAIN:
    case process.env.VUE_APP_PROGRAM_CPR_CA_DOMAIN:
      routes = routerCpr;
      comingSoon = process.env.VUE_APP_PROGRAM_CPR_COMING_SOON == "true";
      break;
    case process.env.VUE_APP_PROGRAM_CCPR_US_DOMAIN:
    case process.env.VUE_APP_PROGRAM_CCPR_CA_DOMAIN:
      routes = routerCcpr;
      comingSoon = process.env.VUE_APP_PROGRAM_CCPR_COMING_SOON == "true";
      break;
    case process.env.VUE_APP_PROGRAM_EUR_US_DOMAIN:
    case process.env.VUE_APP_PROGRAM_EUR_CA_DOMAIN:
      routes = routerEur;
      comingSoon = process.env.VUE_APP_PROGRAM_EUR_COMING_SOON == "true";
      break;
    case process.env.VUE_APP_PROGRAM_CEUR_US_DOMAIN:
    case process.env.VUE_APP_PROGRAM_CEUR_CA_DOMAIN:
      routes = routerCeur;
      comingSoon = process.env.VUE_APP_PROGRAM_CEUR_COMING_SOON == "true";
      break;
    case process.env.VUE_APP_PROGRAM_CEUR_US_LANDING_DOMAIN:
    case process.env.VUE_APP_PROGRAM_CEUR_CA_LANDING_DOMAIN:
      routes = routerCeurLanding;
      comingSoon = process.env.VUE_APP_PROGRAM_CEUR_COMING_SOON == "true";
      break;
    case process.env.VUE_APP_PROGRAM_EVE_US_DOMAIN:
      routes = routerEve;
      comingSoon = process.env.VUE_APP_PROGRAM_EVE_COMING_SOON == "true";
      break;
    default:
      routes = routerCpr;
      comingSoon = process.env.VUE_APP_PROGRAM_CPR_COMING_SOON == "true";
      break;
  }

  return routes;
};

let router = chooseRouter();

// initialize gapp-components library
Vue.use(GappComponents, {
  clientKey: process.env.VUE_APP_CLIENT_KEY,
  programGroupKey: process.env.VUE_APP_PROGRAM_GROUP_KEY,
  baseUrl: process.env.VUE_APP_LOYALTY_API_HOST_US,
  portalUrl: process.env.VUE_APP_PORTAL_URL,
  httpRedirects: { 403: { name: "accessDenied" } },
  env: process.env
});

// Check authentication before each page
router.beforeEach((to, from, next) => {
  // eslint-disable-next-line no-constant-condition

  if (!router.app.$store.state.byPassComingSoonPage && comingSoon) {
    if (!to.query.bypass) {
      if (to.path != "/coming-soon") {
        return next("/coming-soon");
      }
    } else if (to.query.bypass == 1) {
      router.app.$store.commit("toggleByPass");
      return next("/");
    }
  }

  if (to.matched.some(record => record.meta.requiresAuthentication)) {
    router.app.$auth
      .verify()
      .then(() => {
        // Check for Pre-Enrolled
        if (router.app.$auth.isPreEnrolled()) {
          return to.path != "/views/preEnroll" ? next("/views/preEnroll") : next();
        }
        // Check for Unauthorized Access
        let authorizations = to.matched.find(record => record && record.meta && record.meta.participantTypes);
        authorizations = authorizations ? authorizations.meta.participantTypes : null;
        if (
          authorizations &&
          !authorizations.includes(router.app.$store.getters.selectedParticipant.participantType.participantTypeKey)
        ) {
          return to.path != "/accessDenied" ? next("/accessDenied") : next();
        }

        // Check missing profile info or missing security info
        if (SonyProgramService.isChannelPartnerRebates()) {
          if (missingProfileInfo() || missingSecurityAnswer() || missingPayoutMethod()) {
            return to.path != "/profile"
              ? next("/profile?missing=true&moment=" + moment().format("YYYYMMDDTHHmmssSSS"))
              : next();
          }

          return hasPrincipalOwner().then(principalOwner => {
            if (principalOwner) {
              return next();
            } else {
              return to.path != "/missingPrincipalOwner" ? next("/missingPrincipalOwner") : next();
            }
          });
        } else if (SonyProgramService.isEVE()) {
          if (missingProfileInfo() || missingSecurityAnswer() || missingPayoutMethod()) {
            return to.path != "/profile"
              ? next("/profile?missing=true&moment=" + moment().format("YYYYMMDDTHHmmssSSS"))
              : next();
          } else {
            return next();
          }
        } else if (SonyProgramService.isCCPR()) {
          if (missingProfileInfo() || missingSecurityAnswer() || missingPayoutMethod()) {
            return to.path != "/profile"
              ? next("/profile?missing=true&moment=" + moment().format("YYYYMMDDTHHmmssSSS"))
              : next();
          } else {
            return next();
          }
        } else {
          return next();
        }
      })
      .catch(e => {
        console.log(e);
        if (e == "No token") {
          return to.path != "/login" ? next("/login") : next();
        } else {
          return next("/login?error=401");
        }
      });
  } else {
    next();
  }
});

// Apply Google tagging
if (process.env.VUE_APP_GTAG && process.env.VUE_APP_GTAG.length > 0) {
  Vue.use(
    VueGtag,
    {
      config: {
        id: process.env.VUE_APP_GTAG,
        params: {
          cookie_flags: "SameSite=None;Secure"
        }
      }
    },
    router
  );
}

// Setup Vue
let vm = new Vue({
  router: chooseRouter(),
  vuetify,
  render: h => h(App)
}).$mount("#app");

vm.$i18n
  .determine(SonyProgramService.isCaDomain() ? "CA" : "US")
  .then(locale => {
    console.log("Determined locale: " + (locale ? locale.code : "None"));
  })
  .catch(e => {
    console.log(e);
  });

SonyProgramService.init(router);
Vue.prototype.$sonyProgram = SonyProgramService;

function missingParticipantInfo(participant) {
  if (participant && participant.source && participant.source.length > 0) {
    // direct - don't check for missing information
    return false;
  }
  return participant.missingRequiredInformation;
}

function missingOrganizationInfo(participant) {
  let organization = participant.organization;

  if (!organization) {
    return false;
  }

  if (!participant.participantType.allowOrganizationManagement) {
    // don't check when participant does not have ability to management its organization
    return false;
  }

  if (organization.source?.length > 0) {
    // direct - don't check for missing information
    return false;
  }

  return organization.missingRequiredInformation;
}

function missingProfileInfo() {
  let participant = router.app.$store.getters.selectedParticipant;
  if (missingParticipantInfo(participant) || missingOrganizationInfo(participant)) {
    return true;
  }
  return false;
}

function hasPrincipalOwner() {
  let participant = router.app.$store.getters.selectedParticipant;
  if (
    participant &&
    participant.organization &&
    participant.organization.organizationType.organizationTypeKey == "INDIRECT_3PTY_RESELLER"
  ) {
    let flag = false;
    return router.app.$api.post("/api/participants/searchWithinOrganization", {}).then(({ data }) => {
      flag = false;

      for (let part of data.content) {
        if (part.participantType.participantTypeKey == 800) {
          flag = true;
        }
      }
      return Promise.resolve(flag);
    });
  } else {
    return Promise.resolve(true);
  }
}

function missingSecurityAnswer() {
  if (router.app.$store.getters.securityAnswersCount === 0) {
    return true;
  }
  return false;
}

function missingPayoutMethod() {
  let participant = router.app.$store.getters.selectedParticipant;

  return shouldLoadPayoutMethod(participant) && !participant.preferredAwardVehicle;
}

export function shouldLoadPayoutMethod(participant) {
  return (
    participant.participantType?.availableAwardVehicles?.length > 1 &&
    !participant.organization?.forcedAwardVehicle &&
    !participant.forcedAwardVehicle
  );
}
