/**
 * A service for managing promotions and their deadlines.
 */

import moment from "moment-timezone";

const PromotionService = {
  store: null,
  init(opts) {
    if (opts.store) {
      this.store = opts.store;
    }
  },
  /**
   * Finds and assigns the closest deadlines to each promotion item based on the current date.
   * @param {Array} items - Array of promotion items, each containing promotion details and deadlines.
   * @returns {Array} The modified array of items with assigned closest deadline dates.
   */
  findAndAssignClosestDates(items) {
    return items.map(item => {
      const today = moment();
      const { promotionDeadlines } = item.promotion;

      if (promotionDeadlines && promotionDeadlines.length) {
        // Find the closest effectiveDate from the future
        const closestFutureEffectiveDeadline = promotionDeadlines.reduce((closest, current) => {
          const currentEffectiveDate = moment(current.effectiveDate);
          if (
            !closest ||
            (currentEffectiveDate.isAfter(today) && currentEffectiveDate.isBefore(moment(closest.effectiveDate)))
          ) {
            return current;
          }
          return closest;
        }, null);

        // Assign corresponding dates for the closest future effectiveDate
        if (closestFutureEffectiveDeadline) {
          item.closestSubmittableEffectiveDate = closestFutureEffectiveDeadline.effectiveDate;
          item.closestSubmittableExpirationDate = closestFutureEffectiveDeadline.expirationDate;
          item.closestSubmittableSubmissionDeadlineDate = closestFutureEffectiveDeadline.submissionDeadlineDate;
        }
      }

      return item;
    });
  },
  /**
   * Finds the latest submission deadline from a set of promotion deadlines.
   * @param {Array} promotionDeadlines - An array of deadlines from a promotion.
   * @returns {moment.Moment|null} The latest submission deadline date or null if no deadlines exist.
   */
  findLatestSubmissionDeadline(promotionDeadlines) {
    if (promotionDeadlines && promotionDeadlines.length > 0) {
      return promotionDeadlines.reduce((latest, deadline) => {
        const currentDeadlineDate = moment(deadline.submissionDeadlineDate);
        return currentDeadlineDate.isAfter(latest) ? currentDeadlineDate : latest;
      }, moment(0)); // Start with the earliest date possible
    }
    return null; // If no deadlines are found
  },

  isPromotionContestBased(promotion) {
    const { contest, promotionType } = promotion;
    return contest && promotionType.allowClaimsWithNoPayouts;
  }
};

export default PromotionService;
